/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react"
import { Link, graphql } from "gatsby"
import scrollToElement from "scroll-to-element"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Slider from "react-slick"
import ContactMapRow from "../components/contactMapRow"

const IndexPage = ({ data }) => {
  const settingsSlider = {
    slidesToShow: 1,
    infinite: true,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    fade: true,
    pauseOnHover: false,
  }

  return (
    <Layout>
      <Seo title="Hjem" />
      <section className="home_top_back">
        <Slider {...settingsSlider}>
          {data?.home?.acfHome?.hero.map((item, index) => (
            <div key={index}>
              <div
                className="slick-home-wrapper"
                style={{
                  backgroundImage: `url(${item?.background?.localFile?.publicURL})`,
                }}
              >
                <h2>{item?.header}</h2>
                <div className="slick-home-inner">
                  <h3>{item?.subaheader}</h3>
                  <Link to={item?.link} className="btn_custom white">
                    Les mer
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <button
          className="home_top_back--scroll"
          onClick={() => scrollToElement("#mer")}
          aria-label="Les mer"
        ></button>
      </section>

      <span className="anchor" id="mer"></span>
      <section className="home_section_products">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4">
              <h2 className="section_header">
                {data?.home?.acfHome?.contentHeader}
              </h2>
              <br />
              <Link to="/produkter/" className="btn_custom">
                Produkter
              </Link>
            </div>
            <div className="col-lg-8">
              <div className="home_section_products__list">
                <ul>
                  {data?.home?.acfHome?.list?.map((item, index) => (
                    <li key={index}>{item.item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home_section_map">
        <div className="container-fluid">
          <ContactMapRow data={data?.contact?.acfContact} />
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    home: wpPage(id: { eq: "cG9zdDoxNA==" }) {
      acfHome {
        hero {
          background {
            localFile {
              publicURL
            }
          }
          header
          subaheader
          link
        }
        contentHeader
        list {
          item
        }
      }
    }
    contact: wpPage(id: { eq: "cG9zdDo0Mg==" }) {
      acfContact {
        header
        content
        persons {
          image {
            localFile {
              publicURL
            }
          }
          fullName
          email
          phone
          position
        }
      }
    }
  }
`

export default IndexPage
